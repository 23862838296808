<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <b-row class="mt-2">
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                              <b-th colspan="2">Datos Generales</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 10%">Nombre: </b-th>
                                <b-td>{{ hotel.name }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'HotelIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button variant="secondary" :to="{ name: 'HotelEdit', params: { id: hotel.id }}" v-if="hotel.id">
                        <font-awesome-icon icon="edit"></font-awesome-icon> Modificar
                    </b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { hotelUrl } from '@routes';
import * as constants from '@constants';

export default {
    data() {
        return {
            hotel: {
                id: 0,
                name: ''
            },
            breadcrumb: {
                title: 'Hoteles',
                path: [
                    {
                        name: 'HotelIndex',
                        text: 'Hoteles'
                    }
                ]
            },
            isLoading: false
        };
    },
    created() {
        this.hotel.id = this.$route.params.id ?? 0;

        this.breadcrumb.path.push({
            name: 'HotelShow',
            text: 'Detalle'
        });

        this.$emit('update-breadcrumb', this.breadcrumb);

        this.loadData();
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(hotelUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    let hotel = response.data.hotel;

                    this.hotel.id = hotel.id;
                    this.hotel.name = hotel.name;
                }
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    computed: {
    }
};
</script>

<style scoped>

</style>